import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Marker from '../Marker';

import Sky from '../../assets/images/view-1.jpg';

const View1 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} />
      <Marker clickHandler={() => handleMove(2)} position="-3 1 0" />
      <Marker clickHandler={() => handleMove(3)} position="-3 1 2.5" />
      <Marker clickHandler={() => handleMove(12)} position="-0.25 1 2.5" />
    </>
  );
};

export default View1;
