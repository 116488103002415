import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-17.jpg';
import Marker from '../Marker';

const View17 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 0 0" />
      <Marker clickHandler={() => handleMove(15)} position="-2.3 1.25 -0.85" />
      <Marker clickHandler={() => handleMove(16)} position="-0.75 1.25 -2.5" />
      <Marker clickHandler={() => handleMove(18)} position="0.25 1.25 2" />
      <Marker clickHandler={() => handleMove(22)} position="1.25 2.1 -2.75" />
    </>
  );
};

export default View17;
