import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-5.jpg';
import Marker from '../Marker';

const View5 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 0 0" />
      <Marker clickHandler={() => handleMove(4)} position="2.25 1 3" />
      <Marker clickHandler={() => handleMove(6)} position="3 1 -3" />
    </>
  );
};

export default View5;
