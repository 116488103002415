import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-21.jpg';
import Marker from '../Marker';

const View21 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 0 0" />
      <Marker clickHandler={() => handleMove(22)} position="3 3.25 -0.7" />
      <Marker clickHandler={() => handleMove(16)} position="2 2.25 0.75" />
      <Marker clickHandler={() => handleMove(15)} position="2 2.25 3" />
    </>
  );
};

export default View21;
