import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-12.jpg';
import Marker from '../Marker';

const View12 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 0 0" />
      <Marker clickHandler={() => handleMove(1)} position="3 1 0" />
      <Marker clickHandler={() => handleMove(11)} position="-3 1 1" />
    </>
  );
};

export default View12;
