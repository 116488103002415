import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-7.jpg';
import Marker from '../Marker';

const View7 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 0 0" />
      <Marker clickHandler={() => handleMove(6)} position="-4 1 0" />
    </>
  );
};

export default View7;
