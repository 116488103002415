import React from 'react';
import { Box } from '@mui/material';
import LogoImage from '../assets/images/very_hard_logo.svg';

const Logo = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: { xs: 10, md: 15, lg: 20 },
      zIndex: 99,
      width: { xs: 125, md: 150, lg: 200 }
    }}>
    <img style={{ width: '100%' }} src={LogoImage} />
  </Box>
);

export default Logo;
