import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-3.jpg';
import Marker from '../Marker';

const View3 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 0 0" />
      <Marker clickHandler={() => handleMove(1)} position="2.5 1 4" />
      <Marker clickHandler={() => handleMove(2)} position="2.75 1 0.25" />
      <Marker clickHandler={() => handleMove(4)} position="0 1 -4" />
    </>
  );
};

export default View3;
