import { createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: ['IBM Plex Sans', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(',')
  },
  palette: {
    primary: {
      main: '#00a515'
    },
    green: {
      main: '#00fc22'
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      }
    }
  }
});

export default theme;
