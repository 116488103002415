import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-10.jpg';
import Marker from '../Marker';

const View10 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 130 0" />
      <Marker clickHandler={() => handleMove(9)} position="0.5 1 -3" />
      <Marker clickHandler={() => handleMove(11)} position="3 1 -1.25" />
      <Marker clickHandler={() => handleMove(13)} position="-4 1 -1" />
    </>
  );
};

export default View10;
