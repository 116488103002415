import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-9.jpg';
import Marker from '../Marker';

const View9 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 0 0" />
      <Marker clickHandler={() => handleMove(8)} position="2.5 1 0.5" />
      <Marker clickHandler={() => handleMove(10)} position="-2.5 1 -0.5" />
      <Marker clickHandler={() => handleMove(11)} position="-2 1 3" />
    </>
  );
};

export default View9;
