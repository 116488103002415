import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-16.jpg';
import Marker from '../Marker';

const View16 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 0 0" />
      <Marker clickHandler={() => handleMove(15)} position="-2.25 1.25 0.25" />
      <Marker clickHandler={() => handleMove(17)} position="-1 1.25 2" />
      <Marker clickHandler={() => handleMove(21)} position="1.3 0.5 -3" />
      <Marker clickHandler={() => handleMove(22)} position="2 2.25 1.5" />
    </>
  );
};

export default View16;
