import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-13.jpg';
import Marker from '../Marker';

const View13 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 0 0" />
      <Marker clickHandler={() => handleMove(10)} position="-3 1 1" />
      <Marker clickHandler={() => handleMove(14)} position="2 1 1" />
    </>
  );
};

export default View13;
