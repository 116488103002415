import React from 'react';
import { Entity } from 'aframe-react';
import theme from '../theme';

const Marker = ({ clickHandler, ...props }) => (
  <Entity
    primitive="a-sphere"
    radius="0.15"
    opacity={1}
    color={theme.palette.green.main}
    events={{
      click: clickHandler
    }}
    {...props}
  />
);

export default Marker;
