import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-22.jpg';
import Marker from '../Marker';

const View22 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 0 0" />
      <Marker clickHandler={() => handleMove(15)} position="3 1.25 2" />
      <Marker clickHandler={() => handleMove(17)} position="2.75 1 0" />
      <Marker clickHandler={() => handleMove(16)} position="1 0.5 2" />
      <Marker clickHandler={() => handleMove(21)} position="-0.5 0 4" />
    </>
  );
};

export default View22;
