import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-19.jpg';
import Marker from '../Marker';

const View19 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 0 0" />
      <Marker clickHandler={() => handleMove(18)} position="-0.25 1 -4" />
      <Marker clickHandler={() => handleMove(20)} position="-2 1.5 -2.75" />
    </>
  );
};

export default View19;
