import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-15.jpg';
import Marker from '../Marker';

const View15 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 0 0" />
      <Marker clickHandler={() => handleMove(14)} position="-2 0.5 -1" />
      <Marker clickHandler={() => handleMove(16)} position="2.5 0.75 1" />
      <Marker clickHandler={() => handleMove(17)} position="0.5 0.75 2.5" />
      <Marker clickHandler={() => handleMove(18)} position="-1.5 0.75 3.75" />
      <Marker clickHandler={() => handleMove(21)} position="4 0.5 -0.5" />
      <Marker clickHandler={() => handleMove(22)} position="3 1.5 2.75" />
    </>
  );
};

export default View15;
