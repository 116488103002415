import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-6.jpg';
import Marker from '../Marker';

const View6 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 0 0" />
      <Marker clickHandler={() => handleMove(5)} position="-3 1 3.75" />
      <Marker clickHandler={() => handleMove(7)} position="4 1 0" />
      <Marker clickHandler={() => handleMove(8)} position="-2 1 -4" />
    </>
  );
};

export default View6;
