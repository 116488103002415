import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-2.jpg';
import Marker from '../Marker';

const View2 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 0 0" />
      <Marker clickHandler={() => handleMove(1)} position="4 1 0" />
      <Marker clickHandler={() => handleMove(3)} position="1 1 5" />
    </>
  );
};

export default View2;
