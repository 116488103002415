import React from 'react';
import { Alert, Snackbar } from '@mui/material';

const Notifier = () => {
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={20000} onClose={handleClose}>
      <Alert icon={false} onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        Welcome to <strong>Very Hard</strong>! Tap on the green bubbles to move around.
      </Alert>
    </Snackbar>
  );
};

export default Notifier;
