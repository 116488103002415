import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-20.jpg';
import Marker from '../Marker';

const View20 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 0 0" />
      <Marker clickHandler={() => handleMove(19)} position="-1.5 1.25 1.75" />
    </>
  );
};

export default View20;
