import 'aframe';
import 'aframe-particle-system-component';
import { Entity, Scene } from 'aframe-react';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';

import View1 from './views/View1';
import View2 from './views/View2';
import View3 from './views/View3';
import View4 from './views/View4';
import View5 from './views/View5';
import View6 from './views/View6';
import View7 from './views/View7';
import View8 from './views/View8';
import View9 from './views/View9';
import View10 from './views/View10';
import View11 from './views/View11';
import View12 from './views/View12';
import View13 from './views/View13';
import View14 from './views/View14';
import View15 from './views/View15';
import View16 from './views/View16';
import View17 from './views/View17';
import View18 from './views/View18';
import View19 from './views/View19';
import View20 from './views/View20';
import View21 from './views/View21';
import View22 from './views/View22';

import { Box } from '@mui/material';
import Notifier from './Notifier';
import Logo from './Logo';

const views = {
  1: View1,
  2: View2,
  3: View3,
  4: View4,
  5: View5,
  6: View6,
  7: View7,
  8: View8,
  9: View9,
  10: View10,
  11: View11,
  12: View12,
  13: View13,
  14: View14,
  15: View15,
  16: View16,
  17: View17,
  18: View18,
  19: View19,
  20: View20,
  21: View21,
  22: View22
};

const ViewComponent = ({ view, handleMove }) => {
  let { id } = useParams();

  const Component = view ? views[view] : views[id];

  return <Component handleMove={handleMove} />;
};

const containerStyle = {
  '& .a-dialog': {
    p: [1.5, 3],
    borderRadius: 1.5,
    height: 'auto',
    backgroundColor: '#FFF',
    '& .a-dialog-text': {
      fontSize: 14,
      mb: 2,
      color: 'common.black'
    },
    '& .a-dialog-button': {
      color: 'common.white',
      padding: [1, 2],
      borderRadius: 1,
      boxShadow: 0,
      fontSize: 14
    },
    '& .a-dialog-deny-button': {
      color: 'common.black',
      backgroundColor: '#dedfdf'
    },
    '& .a-dialog-allow-button': {
      color: 'common.white',
      backgroundColor: 'common.black'
    }
  }
};

const View = () => {
  const navigate = useNavigate();

  const handleMove = (value) => {
    navigate(`/${value}`);
  };

  return (
    <Box sx={containerStyle}>
      <Logo />
      <Notifier />
      <Scene vr-mode-ui="enabled: false">
        <Entity primitive="a-scene" background="color: #00fc22" />
        <Entity primitive="a-cursor" cursor="rayOrigin: mouse" />
        <Entity primitive="a-camera" wasd-controls-enabled={false} />
        <Routes>
          {Object.keys(views).map((view) => (
            <Route
              path={`/${view}`}
              exact
              element={<ViewComponent view={view} handleMove={handleMove} />}
              key={`route-view-${view}`}
            />
          ))}
          <Route path={`/`} element={<ViewComponent view={10} handleMove={handleMove} />} />
        </Routes>
      </Scene>
    </Box>
  );
};

export default View;
