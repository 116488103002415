import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-4.jpg';
import Marker from '../Marker';

const View4 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 0 0" />
      <Marker clickHandler={() => handleMove(3)} position="-3.5 1 -0.25" />
      <Marker clickHandler={() => handleMove(5)} position="0.5 1 -3" />
    </>
  );
};

export default View4;
