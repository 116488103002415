import 'aframe';
import 'aframe-particle-system-component';
import { Entity } from 'aframe-react';

import Sky from '../../assets/images/view-18.jpg';
import Marker from '../Marker';

const View18 = ({ handleMove }) => {
  return (
    <>
      <Entity primitive="a-sky" src={Sky} rotation="0 0 0" />
      <Marker clickHandler={() => handleMove(8)} position="-0.5 1 3.5" />
      <Marker clickHandler={() => handleMove(9)} position="-3.5 1 3.25" />
      <Marker clickHandler={() => handleMove(19)} position="4 1 -1.3" />
      <Marker clickHandler={() => handleMove(15)} position="-3 1.25 -4.1" />
      <Marker clickHandler={() => handleMove(17)} position="-0.25 1.25 -2.75" />
    </>
  );
};

export default View18;
